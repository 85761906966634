// Filename - App.js

import React, { useState } from "react";
import "./App.css";
import { NavigationBar } from "./components/navbar.js";
import { DraggableWindow } from "./components/windowContainer.js";
import Scanlines from "./components/scanlines";

function App() {

  const [scanlinesEnabled, setScanlinesEnabled] = useState(false); /* Scanlines should be disabled by default */

  return (
    <div className="background-container">
      <Scanlines enabled={scanlinesEnabled} />
      <DraggableWindow setScanlinesEnabled={setScanlinesEnabled} />
      <NavigationBar />
    </div>
  );
}

export default App;
